import React, { useState, useEffect, useMemo } from 'react';

import AdobeContext from './context/adobe-context';
import HydrationContext from './context/hydration-context';

const App = ({ children }) => {
  const [hydrated, setHydrated] = useState(false);
  const [track, setTrack] = useState(null);

  const waitForElement = () => {
    if (typeof window._satellite !== 'undefined') {
      // variable exists, do what you want
      // console.log(`track: ${track}`);
      // console.dir(window.analytics);
      window._satellite.track(track);

      if (track === 'securepay-form-interact') {
        // reset window.analytics
        window.analytics = window.defaultAnalytics;
      }

      setTrack(null);
    } else {
      setTimeout(waitForElement, 250);
    }
  };

  useEffect(() => {
    setHydrated(true);
  }, []);

  useEffect(() => {
    if (track) {
      waitForElement();
    }
  }, [track]);

  const adobeClickHandler = (data) => {
    window.analytics = {
      ...window.analytics,
      ...data,
    };
    setTrack('securepay-form-interact');
  };

  const foo = useMemo(() => ({ setTrack, adobeClickHandler }), []);

  return (
    <HydrationContext.Provider value={hydrated}>
      <AdobeContext.Provider value={foo}>{children}</AdobeContext.Provider>
    </HydrationContext.Provider>
  );
};

export default App;
