exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-help-and-support-contact-sales-index-js": () => import("./../../../src/pages/help-and-support/contact-sales/index.js" /* webpackChunkName: "component---src-pages-help-and-support-contact-sales-index-js" */),
  "component---src-pages-help-and-support-index-js": () => import("./../../../src/pages/help-and-support/index.js" /* webpackChunkName: "component---src-pages-help-and-support-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-gateway-pricing-js": () => import("./../../../src/pages/pricing/gateway-pricing.js" /* webpackChunkName: "component---src-pages-pricing-gateway-pricing-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-pricing-tool-js": () => import("./../../../src/pages/pricing-tool.js" /* webpackChunkName: "component---src-pages-pricing-tool-js" */),
  "component---src-pages-resources-getting-started-js": () => import("./../../../src/pages/resources/getting-started.js" /* webpackChunkName: "component---src-pages-resources-getting-started-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-payment-method-js": () => import("./../../../src/templates/payment-method.js" /* webpackChunkName: "component---src-templates-payment-method-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-seo-js": () => import("./../../../src/templates/seo.js" /* webpackChunkName: "component---src-templates-seo-js" */),
  "component---src-templates-why-securepay-js": () => import("./../../../src/templates/why-securepay.js" /* webpackChunkName: "component---src-templates-why-securepay-js" */)
}

